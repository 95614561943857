/**
 * @author emil.ohman@svenskaspel.se (Emil Öhman)
 */

import PropTypes from 'prop-types';
import './../../stylesheets/speech-bubble.less';

const SpeechBubble = ({
  branding,
  className,
  children,
  rotate,
  size,
  ...props
}) => {
  const classes = ['speech-bubble'];

  if (React.Children.only(children) &&
    React.Children.only(children).props &&
    React.Children.only(children).props.className) {
    classes.push(React.Children.only(children).props.className);
  }

  if (branding) {
    classes.push(`speech-bubble-${branding}`);
  } else {
    classes.push('speech-bubble-default');
  }

  if (size) {
    classes.push(`speech-bubble-${size}`);
  } else {
    classes.push('speech-bubble-500');
  }

  if (rotate) {
    classes.push(`speech-bubble-rotate-${rotate}`);
  }

  if (className) {
    classes.push(className);
  }

  return (
    <div
      className={classes.join(' ')}
      {...props}
    >
      {children}
    </div>
  );
};

SpeechBubble.propTypes = {
  /**
   * Set branding
   */
  branding: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.oneOf(['default', 'tur', 'sport'])
  ]),
  /**
   * Content
   */
  children: PropTypes.node,
  /**
   * Add custom class
   */
  className: PropTypes.string,
  /**
   * Rotatation of bubble
   */
  rotate: PropTypes.oneOf(['', 'positive', 'negative']),
  /**
   * Size of bubble.
   */
  size: PropTypes.oneOf(['100', '200', '300', '400', '500', '600', '700', 'responsive'])
};

export default SpeechBubble;
